import React, { useState } from "react";
import header_img from "../../assets/Header/logo.png";
//import header_imgWhite from "../../assets/Header/logo_white.png";
import { Link } from "react-router-dom";
import { Drawer, List, ListItem, IconButton, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import "./styles.css";
//import { Home } from "../../layout/Home";
import calendarimg from "../../assets/Header/icono-calendario.png";

const Header = (isHome) => {
  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <nav>
      {isMobile ? (
        <>
          <IconButton
            onClick={toggleDrawer(true)}
            aria-label="menu"
            style={{ color: "black" }}
          >
            <MenuIcon />
          </IconButton>

          <Link
            to={{ pathname: "https://www.juleriaque.com.ar/" }}
            target="_blank"
            style={{ display: "flex" }}
          >
            <img
              className="img_header"
              src={header_img}
              alt="Juleriaque logo"
            />
          </Link>

          {/* MENU */}
          <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
            {/* Botón de cerrar fuera del Drawer */}
            <div
              style={{
                position: "absolute",

                right: "0px",
                zIndex: 1301,
              }}
            >
              <IconButton
                onClick={toggleDrawer(false)}
                aria-label="close"
                style={{ color: "#00000054" }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ paddingTop: "10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  paddingLeft: "16px",
                }}
              >
                <Link
                  to={{ pathname: "https://www.juleriaque.com.ar/" }}
                  target="_blank"
                  className="menuIcon"
                >
                  <img
                    className="img_header_menu"
                    src={header_img}
                    alt="Juleriaque logo"
                  />
                </Link>
              </div>
            </div>
            <List
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              style={{ width: 270 }}
              className="menu"
            >
              <Divider className="dividerStyle" />
              <ListItem>
                <Link
                  to="/"
                  style={{ textDecoration: "none", color: "black" }}
                  className="linkTitle"
                >
                  INICIO
                </Link>
              </ListItem>
              <Divider className="dividerStyle" />
              <ListItem>
                <Link
                  to="/eventos"
                  style={{ textDecoration: "none", color: "black" }}
                  className="linkTitle"
                >
                  EVENTOS
                </Link>
              </ListItem>
              <Divider className="dividerStyle" />
              <ListItem>
                <Link
                  to="/reservar-por-consultor"
                  style={{ textDecoration: "none", color: "black" }}
                  className="linkTitle"
                >
                  CITA CON CONSULTOR
                </Link>
              </ListItem>
              <Divider className="dividerStyle" />
              <ListItem>
                <Link
                  to={{ pathname: "https://www.juleriaque.com.ar/" }}
                  target="_blank"
                  style={{ textDecoration: "none", color: "black" }}
                  className="linkTitle"
                >
                  VISITÁ NUESTRO SITIO
                </Link>
              </ListItem>
              <Divider className="dividerStyle" />
            </List>
          </Drawer>
        </>
      ) : (
        <>
          <Link to="/" style={{ display: "flex" }}>
            <img
              className="img_header"
              src={header_img}
              alt="Juleriaque logo"
            />
          </Link>

          <Link className="linkTitle" to="/eventos">
            <p> EVENTOS</p>
          </Link>
          <Link className="linkTitle" to="reservar-por-consultor">
            <p> CITA CON CONSULTOR</p>
          </Link>
          <Link
            to={{ pathname: "https://www.juleriaque.com.ar/" }}
            target="_blank"
            className="linkTitle"
          >
            <p> VISITÁ NUESTRO SITIO</p>
          </Link>
        </>
      )}
    </nav>
  );
};

export { Header };
