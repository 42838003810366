import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import EventCards from "../EventCards";
import "./styles.css";

const StarEvents = ({ events }) => {
  console.log("featuredddddddddddd", events);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  let history = useHistory();

  const handleSingleEvent = (id) => {
    history.push(`/evento/${id}`);
  };

  return (
    <>
      <div className="title">
        <h2 className="title-text">EVENTOS DESTACADOS</h2>
      </div>
      <div className="conteinerMain">
        <div className="containerCard">
          {events?.map((eventFilter) => (
            <EventCards event={eventFilter} />
          ))}
        </div>
      </div>
    </>
  );
};

export { StarEvents };
