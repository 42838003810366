import React, { useState, useEffect } from "react";
import { Header } from "../../components/Header";
import { Slides } from "../../components/Slides";
import { CardButtonNav } from "../../components/CardButtonNav";
import { useEvents } from "../../hooks";
import { EventCards } from "../../components/EventCards";
import "./styles.css";
import { Button } from "@material-ui/core";
import eventImageM from "../../assets/BannerMobile/mimgEvent-min.jpg";
import eventImageD from "../../assets/BannerDesktop/imgevent-min.jpg";
import consultImageM from "../../assets/BannerMobile/mimgCita-min.jpg";
import consultImageD from "../../assets/BannerDesktop/imgcita-min.jpg";
import bannerHomeM from "../../assets/BannerMobile/mheader-min.jpg";
import bannerHomeD from "../../assets/BannerDesktop/header-min.jpg";
import { StarEvents } from "../../components/StarEvents";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  const { events, featured, bookingsEvent } = useEvents();
  const [ButtonEvent, setButtonEvent] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [starEvents, setStartedEvents] = useState([]);

  useEffect(() => {
    // console.log("test", isMobileDevice());
    setIsMobile(isMobileDevice()); // Detecta el dispositivo cuando el componente se monta
  }, []);

  useEffect(() => {
    if (events) {
      const filteredEvents = events.filter((event) => event.star === true);
      console.log("filteredEvents", filteredEvents);
      setStartedEvents(filteredEvents);
    }
  }, [events]);

  const handleShowEvents = () => {
    setButtonEvent(!ButtonEvent);
  };

  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };

  const eventImage = isMobile ? eventImageM : eventImageD;
  const consultImage = isMobile ? consultImageM : consultImageD;
  const bannerhome = isMobile ? bannerHomeM : bannerHomeD;

  return (
    <div className="homeDiv">
      <Header isHome={true} />
      <div className="bannerHome">
        <img className="bannerHomeImg" src={bannerhome} alt="banner" />
      </div>
      {featured && <Slides featured={featured} />}
      {!ButtonEvent && <EventCards events={events} />}
      {ButtonEvent && (
        <div className="container-btn">
          <CardButtonNav
            className="cardButtonNav"
            sx={{ boxShadow: 0 }}
            linkNav="/eventos"
            title=""
            srcImage={eventImage}
          />
          <CardButtonNav
            className="cardButtonNav"
            linkNav="/reservar-por-consultor"
            title=""
            srcImage={consultImage}
          />
        </div>
      )}
      {starEvents.length > 0 && (
        <>
          <StarEvents events={starEvents} />
          <div className="divButtonVer">
            <Link to="/eventos" className="link">
              <button className="button"> VER TODOS</button>
            </Link>
          </div>
        </>
      )}

      <Footer />
    </div>
  );
};

export { Home };

/* history.push(`/private/singleBingo/${rowData._id}`
 <Route exact path="/private/singleBingo/:id" component={SingleBingo} />
 
 { match, history }
 useEffect(() => {
    dispatch(singleBingo(match.params.id));
  }, [dispatch, match]);
  */
