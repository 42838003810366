import moment from "moment";

export const TURN_TIME = [
  "0:00",
  "0:30",
  "1:00",
  "1:30",
  "2:00",
  "2:30",
  "3:00",
  "3:30",
  "4:00",
  "4:30",
  "5:00",
  "5:30",
  "6:00",
  "6:30",
  "7:00",
  "7:30",
  "8:00",
  "8:30",
  "9:00",
  "9:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const ZONES = [
  "VIRTUAL",
  "ESPECIAL",
  "CABA",
  "GRAN BUENOS AIRES",
  "MAR DEL PLATA",
  "ROSARIO",
  "CÓRDOBA",
  "MENDOZA",
  "NEUQUÉN",
  "SALTA",
  "TUCUMÁN",
];

export const daysConsultant = {
  "Monday": "Lunes",
  "Tuesday": "Martes",
  "Wednesday": "Miércoles",
  "Thursday": "Jueves",
  "Friday": "Viernes",
  "Saturday": "Sábado",
  "Sunday": "Domingo"
}
export const consultDays = {
  "Lunes": "Monday",
  "Martes": "Tuesday",
  "Miércoles": "Wednesday",
  "Jueves": "Thursday",
  "Viernes": "Friday",
  "Sábado": "Saturday",
  "Domingo": "Sunday"
}


// export const currentWeek = () => {

//   const a = moment().isoWeek() + 1

//   let startOfWeek = moment().isoWeek(Number)//moment().startOf("isoWeek");
//   let endOfWeek = (moment().isoWeek(a))//moment().endOf("isoWeek");


//   let days = {};
//   let day = startOfWeek;

//   while (day <= endOfWeek) {
//     days[day.toDate().getDay()] = day.toDate();
//     day = day.clone().add(1, "d");
//   }

//   return days
// }

export const setStackHoursStore = (starthour, endHour) => {
  let starthhmm = null;
  let endhhmm = null;
  let _starthour = Number(starthour.toString().slice(0, 2));
  let _enhour = Number(endHour.toString().slice(0, 2));
  let _startmin = Number(starthour.toString().slice(2)) > 30 ? "00" : "30";
  let _endmin = Number(endHour.toString().slice(2)) > 30 ? "00" : "30";
  if (_starthour === 0) starthhmm = "08:00";
  else if (_enhour >= 23) endhhmm = "23:30";
  else {
    starthhmm = `${_starthour}:${_startmin}`;
    endhhmm = `${_enhour}:${_endmin}`;
  }

  return TURN_TIME.slice(
    TURN_TIME.indexOf(starthhmm),
    TURN_TIME.indexOf(endhhmm) + 1
  );
};

export const stackHoursStore = (timeStoreEvent) => {
  let res = {}
  let starthhmm = null;
  let endhhmm = null;

  let _startmin = null;
  let _endmin = null;
  // let startHour = 1200
  //let endHour = 2100

  let _starthour = Number(timeStoreEvent[0].startHour < 1000 ? timeStoreEvent[0].startHour.toString().slice(0, 1) : timeStoreEvent[0].startHour.toString().slice(0, 2));
  let _enhour = Number(timeStoreEvent[0].endHour < 1000 ? timeStoreEvent[0].endHour.toString().slice(0, 1) : timeStoreEvent[0].endHour.toString().slice(0, 2));
  if (Number(timeStoreEvent[0].startHour < 1000)) {
    _startmin = Number(timeStoreEvent[0].startHour.toString().slice(1)) > 30 || Number(timeStoreEvent[0].startHour.toString().slice(1)) === 0 ? "00" : "30";
  } else {
    _startmin = Number(timeStoreEvent[0].startHour.toString().slice(2)) > 30 || Number(timeStoreEvent[0].startHour.toString().slice(2)) === 0 ? "00" : "30";
  }

  _endmin = Number(timeStoreEvent[0].endHour.toString().slice(2)) > 30 || Number(timeStoreEvent[0].endHour.toString().slice(2)) === 0 ? "00" : "30";

  if (_starthour < 10) {
    // starthhmm = "08:00";
    starthhmm = `0${_starthour.toString()}:00`;
    // endhhmm = `0${_enhour}:00`;
  }
  if (endhhmm < 10) {
    // starthhmm = "08:00";
    // starthhmm = `0${_starthour}:00`;
    endhhmm = `0${_enhour.toString()}:00`;
  }
  // if (1 <= _starthour <= 9){
  // }
  // if (_enhour >= 23) endhhmm = "23:30";

  starthhmm = `${_starthour}:${_startmin}`;
  endhhmm = `${_enhour}:${_endmin}`;


  const arr = TURN_TIME.slice(
    TURN_TIME.indexOf(starthhmm),
    TURN_TIME.indexOf(endhhmm)
  )
  arr.forEach((time) => {
    res[time.toString()] = {
      cupos: timeStoreEvent[0].limit_place
    }
  })

  return res
};

export const setStackHoursConsult = (starthour, endHour) => {
  let res = {}
  let starthhmm = null;
  let endhhmm = null;
  /*let _starthour = Number(starthour.toString().slice(0, 2));
  let _enhour = Number(endHour.toString().slice(0, 2));
  let _startmin = Number(starthour.toString().slice(2)) > 30 ? "00" : "30";
  let _endmin = Number(endHour.toString().slice(2)) > 30 ? "00" : "30";
  if (_starthour === 0) starthhmm = "08:00";
  else if (_enhour >= 23) endhhmm = "23:30";
  else {
    starthhmm = `${_starthour}:${_startmin}`;
    endhhmm = `${_enhour}:${_endmin}`;
  }*/
  let _starthour = Number(starthour < 1000 ? starthour.toString().slice(0, 1) : starthour.toString().slice(0, 2));
  //console.log('starthour ->', _starthour)
  let _enhour = Number(endHour < 1000 ? endHour.toString().slice(0, 1) : endHour.toString().slice(0, 2));
  //console.log('enhour ->', _enhour)

  let _startmin = Number(starthour.toString().slice(2)) > 30 || Number(starthour.toString().slice(2)) === 0 ? "00" : "30";
  let _endmin = Number(endHour.toString().slice(2)) > 30 || Number(endHour.toString().slice(2)) === 0 ? "00" : "30";

  if (_starthour < 10) {
    // starthhmm = "08:00";
    starthhmm = `0${_starthour.toString()}:00`;
    // endhhmm = `0${_enhour}:00`;
  }
  if (endhhmm < 10) {
    // starthhmm = "08:00";
    // starthhmm = `0${_starthour}:00`;
    endhhmm = `0${_enhour.toString()}:00`;
  }
  // if (1 <= _starthour <= 9){
  // }
  // if (_enhour >= 23) endhhmm = "23:30";

  starthhmm = `${_starthour}:${_startmin}`;
  endhhmm = `${_enhour}:${_endmin}`;

  const arr = TURN_TIME.slice(
    TURN_TIME.indexOf(starthhmm),
    TURN_TIME.indexOf(endhhmm)
  )

  arr.forEach((time) => {
    res[time.toString()] = {
      cupos: 1
    }
  })
  return res
};

export const convertEsDay = (day) => {
  return daysConsultant[day]
};

const transformTimestoreevent = (data) => {
  const result = {};

  data.forEach((item) => {
    // Dividir la cadena de fechas en un array de fechas individuales
    const daysArray = item.day.split(", ");

    daysArray.forEach((day) => {
      // Convertir el formato de la fecha a 'YYYY-MM-DD'
      const formattedDay = moment(day, "DD/MM/YYYY").format("YYYY-MM-DD");

      // Verificar si el día ya existe en el objeto result
      if (!result[formattedDay]) {
        result[formattedDay] = {};
      }

      // Calcular los intervalos de tiempo
      let formattedTime = String(item.startHour).padStart(4, "0"); // Asegurarse de que tenga 4 dígitos
      let currentTime = moment(formattedTime, "HHmm");
      const endTime = moment(item.endHour, "HHmm").subtract(item.time, 'minutes');

      while (currentTime <= endTime) {
        const timeKey = currentTime.format("HH:mm");

        // Asignar el limit_place (cupos) al intervalo de tiempo
        result[formattedDay][timeKey] = { cupos: item.limit_place };

        // Incrementar el tiempo basado en el valor de 'time'
        currentTime.add(item.time, 'minutes');
      }
    });
  });
  //console.log('result', result)

  return result;
}

const stackEventDays = (timeStoreEvent) => {
  let res = {}
  console.log('timeStoreEvent', timeStoreEvent)
  let end = moment(timeStoreEvent[0].endDate).format('YYYY-MM-DD')
  end = '2024-10-21'
  let startDate = '2024-09-21'
  if (new Date(startDate) >= new Date()) {
    for (var d = new Date(startDate); d <= new Date(end); d.setDate(d.getDate() + 1)) {
      let key = moment(d).format('YYYY-MM-DD')
      let stackTime = stackHoursStore(timeStoreEvent)
      res[key] = { ...stackTime }
    }
  } else {
    let to = new Date(end).setDate(new Date(end).getDate() + 1)
    for (var f = new Date(); f <= to; f.setDate(f.getDate() + 1)) {
      let stackTime = stackHoursStore(timeStoreEvent)
      let key = moment(f).format('YYYY-MM-DD')
      res[key] = { ...stackTime }
    }
  }

  console.log('ressssssssssssssssssssseeeeeeeeeeeeee', res)
  return res
}
const stackConsultantDays = (workDay) => {
  let date = moment()
  let today = moment().format("YYYY-MM-DD")
  let res = {}
  /*const stackDay = daysConsultant[workDay.day].toUpperCase()
  console.log("days", date)
  console.log("today", today)
  for (let x = 0; x < 3; x++) {
    let daysInMonth = date.daysInMonth();
    for (let d = 1; d <= daysInMonth; d++) {
      let isDay = stackDay === ((date.date(d)).format('dddd')).toUpperCase()
      let nowahora = moment(date.date(d)).format("YYYY/MM/DD")
      let isDayTrue = moment(date.date(d)).isAfter(today)
      if (isDay && isDayTrue) {
        let stackTime = setStackHoursConsult(workDay.startHour, workDay.endHour)
        res[nowahora] = { ...stackTime }
      }
    }
    date.add(1, 'month');
  }*/
  let dates = workDay.day.split(', ');
  dates.map((d) => {
    let stackTime = setStackHoursConsult(workDay.startHour, workDay.endHour)
    let datePieces = d.split('/');
    //console.log('1111111:', datePieces);
    //console.log('222222222:', datePieces[2] + '/' + datePieces[1] + '/' + datePieces[0]);

    // console.log('3333333333:', s);
    return res[datePieces[2] + '/' + datePieces[1] + '/' + datePieces[0]] = { ...stackTime }
  })
  //console.log('workDayasssssssss', workDay.day.split(', '));

  // console.log('resultdaaaaaaaaaaaays', res)
  return res
}


export const getLimit = (allStores, timespanstoreevents, allBookings) => {
  console.log('allStores', allStores)
  let res = {}
  let now = moment(new Date()).format('YYYY-MM-DD')
  allStores.forEach((store) => {
    const timeFound = timespanstoreevents.filter((time) => {
      return time.storeId === Number(store.id);
    });
    //console.log('timeFoundtimeFound', timeFound, store.id)
    const days = transformTimestoreevent(timeFound)
    //console.log('daaaaaaaaaaaaaaaaaaaaysssssssssssssss', days)
    res[store.id] = {
      ...days
    }
  });
  allBookings.forEach((booking) => {
    let day = moment(booking.day).format('YYYY-MM-DD')
    //console.log("allBookings", [booking.storeId][day])
    if (res[booking.storeId]) {
      if (res[booking.storeId][day]) {
        if (res[booking.storeId][day][booking.time]) {
          if (day >= now) {
            res[booking.storeId][day][booking.time].cupos = (res[booking.storeId][day][booking.time].cupos - 1)
          }
        }
      }
    }
  })

  return res
};

export const getLimitEvent = (allWorkDays, allRestingDays, allBookings) => {
  console.log('allWorkDays', allWorkDays)
  //console.log('allRestingDays', allRestingDays)
  // console.log('allBookings', allBookings)
  /*let res = {}
  let rest = {}
  let now = new Date().getDate()

  allRestingDays.forEach((restDay) => {
    const days = stackConsultantDays(restDay)

    let keysDay = Object.keys(days)

    keysDay.map((k) => {

      if (rest[restDay.consultantId]) {
        if (rest[restDay.consultantId][k]) {
          rest[restDay.consultantId][k] = { ...rest[restDay.consultantId][k], ...days[k] }
        } else {
          rest[restDay.consultantId] = { ...rest[restDay.consultantId], ...days }
        }
      } else {
        rest[restDay.consultantId] = { ...rest[restDay.consultantId], ...days }
      }
    })

  });
  allWorkDays.forEach((workDay) => {
    const days = stackConsultantDays(workDay)
    res[workDay.consultantId] = { ...res[workDay.consultantId], ...days }

  });
  let workDates = Object.keys(res[allWorkDays[0].consultantId]);

  workDates.map((w) => {

    if (rest[allWorkDays[0].consultantId][w]) {
      let hours = Object.keys(rest[allWorkDays[0].consultantId][w])
      hours.map((m) => {
        delete res[allWorkDays[0].consultantId][w][m]
      })
    }

  })


  if (allBookings.length > 0) {
    allBookings.forEach((booking) => {
      let _day = moment(booking.day).format('YYYY/MM/DD')

      var day = new Date(_day).getDate();

      if (day >= now) {
        if (res[booking.consultantId][_day]) {
          if (res[booking.consultantId][_day][booking.time]) {
            res[booking.consultantId][_day][booking.time].cupos = (res[booking.consultantId][_day][booking.time].cupos - 1)
          }
        }
      }
    })

    return res
  } else {
    return res
  }*/
};

export const getLimitConsultant = (allWorkDays, allRestingDays, allBookings) => {
  let res = {}
  let rest = {}
  let now = new Date().getDate()
  //console.log('allRestingDaysssssssssss', stackConsultantDays(allRestingDays));
  // console.log('allRestingDays', allRestingDays);
  allRestingDays.forEach((restDay) => {
    const days = stackConsultantDays(restDay)
    // console.log('restDayyyyyyyy', restDay)
    // console.log('days', days)
    let keysDay = Object.keys(days)
    //rest[restDay.consultantId] = { ...rest[restDay.consultantId], ...days }
    keysDay.map((k) => {
      // console.log('dayskkkkkkkkkkkkkkkkk', days[k])
      if (rest[restDay.consultantId]) {
        if (rest[restDay.consultantId][k]) {
          rest[restDay.consultantId][k] = { ...rest[restDay.consultantId][k], ...days[k] }
        } else {
          rest[restDay.consultantId] = { ...rest[restDay.consultantId], ...days }
        }
      } else {
        rest[restDay.consultantId] = { ...rest[restDay.consultantId], ...days }
      }
    })

    // console.log('keysDay', keysDay)
    //console.log('daysdaysdays', days)
    //console.log('rest[restDay.consultantId]', rest[restDay.consultantId])
    //rest[restDay.consultantId] = { ...rest[restDay.consultantId], ...days }

  });
  allWorkDays.forEach((workDay) => {
    const days = stackConsultantDays(workDay)
    res[workDay.consultantId] = { ...res[workDay.consultantId], ...days }

  });
  //let restDates = Object.keys(rest[allWorkDays[0].consultantId]);
  let workDates = Object.keys(res[allWorkDays[0].consultantId]);

  workDates.map((w) => {

    if (rest[allWorkDays[0].consultantId][w]) {
      let hours = Object.keys(rest[allWorkDays[0].consultantId][w])
      //console.log('Object.keys(rest[allWorkDays[0].consultantId].w', rest[allWorkDays[0].consultantId].w);
      hours.map((m) => {
        delete res[allWorkDays[0].consultantId][w][m]
      })
    }

  })

  // console.log('testing workDates: ', workDates);
  // console.log('testing rest: ', rest);
  // console.log('testing res: ', res);

  if (allBookings.length > 0) {
    allBookings.forEach((booking) => {
      let _day = moment(booking.day).format('YYYY/MM/DD')
      //var day = new Date(_day).getDate() + 1;
      var day = new Date(_day).getDate();
      //console.log("daaaaaaaaaay", _day)
      if (day >= now) {
        // console.log("booking", booking)
        // console.log("_day", _day)
        // console.log("now", now);
        //  console.log("day", day)
        if (res[booking.consultantId][_day]) {
          if (res[booking.consultantId][_day][booking.time]) {
            res[booking.consultantId][_day][booking.time].cupos = (res[booking.consultantId][_day][booking.time].cupos - 1)
          }
        }
      }
    })

    return res
  } else {
    return res
  }
};
export const storeWithEvents = (timeStoreEvent) => {
  let result = []
  // let end = moment(timeStoreEvent.endDate).format('YYYY-MM-DD')
  timeStoreEvent.forEach((event) => {
    for (var d = new Date(event.startDate); d <= new Date(event.endDate); d.setDate(d.getDate() + 1)) {
      let key = moment(d).format('YYYY/MM/DD')
      // let stackTime = stackHoursStore(timeStoreEvent, storeId)
      result.push(key)
    }
  })
  return result
}