import React, { useState, useEffect } from "react";
import { Header } from "../../components/Header";
//import { Slides } from "../../components/Slides";
import Select from "react-select";
import {
  Drawer,
  Button,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterIcon from "../../assets/filter.png";
//import SearchIcon from "@material-ui/icons/Search";
//import FilterAltIcon from "@material-ui/icons/Filter";
//import FilterAltOutlinedIcon from "@material-ui/icons/FilterAltOutlined";
//import InputBase from "@material-ui/core/InputBase";

import moment from "moment";

import { useEvents } from "../../hooks";
import { EventCards } from "../../components/EventCards";
import { GoBackFabBtn } from "../../components/GoBackButton";
import "./styles.css";
//import { lightGreen } from "@material-ui/core/colors";

import noEvents from "../../assets/banner-nohayeventos.jpg";
import noEventsM from "../../assets/banner-nohayeventosm.jpg";
import EventBannerM from "../../assets/BannerMobile/mheaderEvent-min.jpg";
import EventBannerD from "../../assets/BannerDesktop/headerEvents.jpg";
import { Footer } from "../../components/Footer";

/*const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#eaeaf0",

    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "50vh",
    height: "5.7vh",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));*/
const Events = () => {
  const { events } = useEvents();
  //const [ButtonEvent, setButtonEvent] = useState(true);
  /*const handleShowEvents = () => {
    setButtonEvent(!ButtonEvent);
  };*/
  const [drawerOpen, setDrawerOpen] = useState(false);

  const now = moment().format("DD/MM/YYYY");
  const [filteredList, setFilteredList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  //const [filterAux, setFilterAux] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedStore, setSelectedStore] = useState([]);
  const [eventsEnabled, setEventsEnabled] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [brands, setBrands] = useState([{ value: "", label: "MARCAS" }]);
  const [stores, setStores] = useState([{ value: "", label: "TIENDAS" }]);
  //const [location, setLocation] = useState([]);
  const [locations, setLocations] = useState([
    //{ value: "", label: "LOCALIDADES" },
    { value: "", label: "TODAS LAS LOCALIDADES" },
    { value: "CABA", label: "CABA" },
    { value: "GRAN BUENOS AIRES", label: "GRAN BUENOS AIRES" },
    { value: "MAR DEL PLATA", label: "MAR DEL PLATA" },
    { value: "ROSARIO", label: "ROSARIO" },
    { value: "CÓRDOBA", label: "CÓRDOBA" },
    { value: "MENDOZA", label: "MENDOZA" },
    { value: "NEUQUÉN", label: "NEUQUÉN" },
    { value: "SALTA", label: "SALTA" },
    { value: "TUCUMÁN", label: "TUCUMÁN" },
  ]);
  const [categories, setCategories] = useState([
    //{ value: "", label: "CATEGORIAS" },
    { value: "", label: "TODAS LAS CATEGORIAS" },
    { value: "fmasculino", label: "FRAGANCIA MASCULINA" },
    { value: "ffemenina", label: "FRAGANCIA FEMENINA" },
    { value: "maquillaje", label: "MAQUILLAJE" },
    { value: "tratamientos", label: "TRATAMIENTOS" },
  ]);
  let filterPressed = false;

  /*const handleBrandChange = (event) => {
    setSelectedBrand(event.value);
  };

  const handleCategoryChange = (event) => {
    console.log("event category", event);
    setSelectedCategory(event.value);
  };*/

  const handleLocationChange = (selectedOption) => {
    // console.log("test", selectedOption);
    setSelectedLocation(selectedOption);
  };

  const toggleDrawer = (open) => (event) => {
    /* if (selectedLocation === "") {
      console.log("hii");
    }*/
    /*console.log(
      "filtros",
      selectedBrand.length,
      selectedCategory.length,
      selectedLocation
    );*/
    //console.log("setDrawerOpen", open);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const filterApply = () => {
    const filtered = eventsEnabled.filter((ev) => {
      // Comprobar categorías seleccionadas dentro de "type" del evento
      const matchCategory =
        selectedCategory.length === 0 || // Si no hay categorías seleccionadas, no filtra por categoría
        selectedCategory.some((cat) => {
          const eventCategories = ev.type.split(",").map((t) => t.trim()); // Dividir el string "type" en un array de categorías
          return eventCategories.includes(cat.value); // Verificar si alguna categoría seleccionada está en las categorías del evento
        });

      // Comprobar marcas seleccionadas dentro de "brands" del evento
      const matchBrand =
        selectedBrand.length === 0 ||
        selectedBrand.some((brand) =>
          ev.brands.some((evBrand) => evBrand.id === brand.value)
        );

      // Comprobar tiendas seleccionadas dentro de "stores" del evento
      const matchStore =
        selectedStore.length === 0 ||
        selectedStore.some((store) =>
          ev.stores.some((evStore) => evStore.id === store.value)
        );

      // Comprobar localidades seleccionadas dentro de "stores" del evento

      //console.log("selectedLocation", selectedLocation);
      const matchLocation =
        !selectedLocation || // Si no hay localidad seleccionada, no filtra por ubicación
        ev.stores.some(
          (store) =>
            store.city.toUpperCase() === selectedLocation.label.toUpperCase()
        );

      // Filtrar si coinciden las categorías, marcas y localidades
      return matchCategory && matchBrand && matchLocation && matchStore;
    });

    const categoryText =
      selectedCategory.length > 0
        ? selectedCategory.map((category) => category.label).join(" - ")
        : "";

    // Construir texto de marcas seleccionadas (array de objetos)
    const brandText =
      selectedBrand.length > 0
        ? selectedBrand.map((brand) => brand.label).join(" - ")
        : "";

    // Construir texto de ubicación seleccionada (objeto)
    const locationText = selectedLocation?.label || ""; // Si no hay ubicación seleccionada, queda vacío

    // Construir texto de marcas seleccionadas (array de objetos)
    const storeText =
      selectedStore.length > 0
        ? selectedStore.map((store) => store.label).join(" - ")
        : "";

    // Combinar todos los textos, filtrando los vacíos
    const filterTexto = [categoryText, brandText, locationText, storeText]
      .filter((text) => text !== "")
      .join(" / ");

    //console.log("selectedCategory", selectedCategory);
    // console.log("selectedBrand", selectedBrand);
    //console.log("selectedLocation", selectedLocation);
    //console.log("filterTexto", filterTexto);
    setFilterText(filterTexto);
    setFilteredList(filtered);
    setDrawerOpen(false);
  };

  const handleClearFilters = () => {
    //console.log("clear");
    setSelectedCategory([]);
    setSelectedBrand([]);
    setSelectedStore([]);
    setSelectedLocation("");
    setFilteredList(eventsEnabled);
    setFilterText("");
  };

  /*useEffect(() => {
    const filtered = eventsEnabled.filter((ev) => {
      return (
        (selectedCategory === "" || ev.type === selectedCategory) &&
        (selectedBrand === "" ||
          ev.brands.find((e) => e.id === selectedBrand)) &&
        (selectedLocation === "" ||
          ev.stores.find((e) => e.city.toUpperCase() === selectedLocation))
      );
    });
    //console.log("selectedBrand", selectedBrand);
    //console.log("selectedCategory", selectedCategory);
    //console.log("selectedLocation", selectedLocation);
    setFilteredList(filtered);
  }, [selectedCategory, selectedBrand, selectedLocation, eventsEnabled]);*/

  const formatBrand = (str) => {
    //if (str[0] === "") str.shift();
    let strFormat = str;
    //console.log("str", str[0]);
    if (str[0] === "1" || str[0] === "2") strFormat = str.slice(1);
    return strFormat
      .toLowerCase()
      .replace(/^[a-z]/g, (x) => x.toUpperCase())
      .replace(/-([a-z]|\&)/g, (_, x) => " " + x.toUpperCase())
      .toUpperCase();
  };

  useEffect(() => {
    // console.log("eveeentsssssssssssssssss", events);
    let eventsEnable = [];
    let brandsList = [];
    let storesList = [];
    // let locationList = [];
    events?.forEach((ev) => {
      if (ev.enabled === true) {
        let dateEvent = ev.timespanstoreevents;
        let brand = ev.brands;
        let tiendas = ev.stores;
        let isInDate = false;
        console.log("ev", ev);
        dateEvent.map((d) => {
          const daysArray = d.day.split(", ");
          for (let day of daysArray) {
            if (
              moment(day, "DD/MM/YYYY").isSameOrAfter(moment(now, "DD/MM/YYYY"))
            ) {
              isInDate = true;
              break;
            }
          }
        });
        if (isInDate) {
          if (!eventsEnable.some((e) => e.id === ev.id)) {
            eventsEnable.push(ev);
          }
          brand.map((b) => {
            let brandObject = { value: b.id, label: formatBrand(b.name) };
            if (!brandsList.some((e) => e.value === brandObject.value)) {
              brandsList.push({ value: b.id, label: formatBrand(b.name) });
            }
          });

          tiendas.map((s) => {
            // Verificar si la tienda tiene al menos una fecha futura
            const hasFutureDate = dateEvent.some(
              (d) =>
                d.storeId === s.id &&
                d.day
                  .split(", ")
                  .some((day) =>
                    moment(day, "DD/MM/YYYY").isSameOrAfter(
                      moment(now, "DD/MM/YYYY")
                    )
                  )
            );

            // Solo agregar tiendas con fechas futuras
            if (hasFutureDate) {
              let storeObject = { value: s.id, label: formatBrand(s.name) };
              if (!storesList.some((e) => e.value === storeObject.value)) {
                storesList.push(storeObject);
              }
            }
          });
        }
      }

      setEventsEnabled(eventsEnable);
    });
    sorting(brandsList);
    brandsList.unshift({ value: "", label: "TODAS LAS MARCAS" });
    //brandsList.unshift({ value: "", label: "MARCAS" });
    sorting(storesList);
    setStores(storesList);
    setBrands(brandsList);
  }, [events]);

  const sorting = (items) => {
    items.sort((a, b) => {
      const nameA = a.label; // ignore upper and lowercase
      const nameB = b.label; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  };
  //console.log("locations", location);
  useEffect(() => {
    //console.log("eventsEnabled", eventsEnabled);
    setFilteredList(eventsEnabled);
    /*events?.map((event) => {
      let brand = event.brands;
      brand.map((b) => {
        if (!brands.includes(b.name)) {
          setBrands([...brands, b.name]);
        }
      });
      let store = event.stores;*/

    /*store.map((s) => {
        if (!locations.includes(s.city)) {
          setBrands([...locations, s.city]);
        }
      });*/
    //});
  }, [eventsEnabled]);

  //console.log("brands", brands);

  /*useEffect(() => {
    const filteredData = filterByBrand(events);
    setFilteredList(filteredData);
  }, [selectedBrand]);*/

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;
  //const classes = useStyles();

  const customStylesMobile = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "grey",
      color: state.isFocused ? "black" : "grey",
      padding: 10,
      fontSize: 10,
      background: "#ebddd2",
    }),
    control: () => ({
      width: 100,
      height: 30,
      backgroundColor: "black",
      color: "white",
      border: "none",
      borderRadius: 40,
      zIndex: 2,
      position: "inherit",
    }),
    menu: (provided) => ({
      ...provided,
      width: 150,
      marginTop: 1,
      left: -20,
      color: "black",
      paddingTop: 20,
      paddingBottom: 10,
      paddingLeft: 10,

      borderRadius: "0 0 30px 30px",
      background: "rgb(235,221,210)",
      zIndex: 1,
      height: 230,
      overflowY: "auto",
      // scrollbarWidth: "none",
    }),
    placeholder: () => ({
      color: "white",
      paddingTop: 10,
      textAlign: "center",
      fontSize: 10,
    }),
    singleValue: () => ({
      color: "white",
      paddingTop: 10,
      textAlign: "center",
      fontSize: 10,
      zIndex: 2,
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    valueContainer: () => ({
      height: 60,
      zIndex: 2,
      position: "inherit",
    }),
  };

  const testStyle2 = {
    control: (provided) => ({
      ...provided,
      border: "none", // Quitar todos los bordes del select
      boxShadow: "none", // Quitar la sombra del control
      minHeight: "auto", // Ajustar la altura
    }),
    menu: (provided) => ({
      ...provided,
      position: "relative", // Mantiene el menú en el flujo del documento
      border: "none", // Eliminar los bordes de la caja desplegable
      boxShadow: "none", // Quitar sombras en el menú
      zIndex: "1",
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
      border: "none", // Elimina el borde de la lista
      maxHeight: "500px", // Altura máxima antes de que aparezca la barra de desplazamiento
      overflowY: "auto",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black", // Color del indicador de dropdown
    }),
    indicatorSeparator: () => ({
      display: "none", // Eliminar la barra separadora
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black", // Color del texto del placeholder
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Cambia el color del texto seleccionado
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "14px", // Tamaño de fuente más pequeño
      paddingLeft: "20px", // Agregar padding izquierdo
    }),
  };

  const testStyle = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (provided) => ({
      ...provided,
      border: "none", // Quita todos los bordes
      borderBottom: "2px solid #6d6d6d", // Aplica solo el borde inferior
      boxShadow: "none", // Quita el efecto de foco
      borderRadius: "0", // Asegura que no haya esquinas redondeadas
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "black", // Opcional: puedes ajustar otros estilos
    }),
    indicatorSeparator: () => ({
      // Oculta la barra separadora entre indicadores
      display: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "black", // Color del texto del placeholder
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Cambia el color del texto seleccionado
    }),
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "grey",
      color: state.isFocused ? "black" : "grey",
      padding: 10,
      fontSize: 14,
      background: "#ebddd2",
    }),
    control: () => ({
      width: 330,
      backgroundColor: "black",
      color: "white",
      border: "none",
      borderRadius: 40,
      zIndex: 2,
      position: "inherit",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 330,
      marginTop: -30,

      color: "black",
      paddingTop: 60,
      paddingBottom: 40,
      paddingLeft: 30,
      paddingRight: 20,
      borderRadius: "0 0 30px 30px",
      background: "rgb(235,221,210)",
      zIndex: 1,
      //height: 300,
      //overflowY: "auto",
      //scrollbarWidth: "none",
    }),
    placeholder: () => ({
      color: "white",
      paddingTop: 20,
      paddingLeft: 30,
    }),
    singleValue: () => ({
      color: "white",
      paddingTop: 20,
      paddingLeft: 30,
      zIndex: 2,
    }),
    indicatorsContainer: () => ({
      display: "none",
    }),
    valueContainer: () => ({
      height: 60,
      zIndex: 2,
      position: "inherit",
    }),
  };

  const openFilters = () => {
    filterPressed = !filterPressed;
  };

  const bannerevent = isMobile ? EventBannerM : EventBannerD;

  return (
    <div>
      <div>
        {/*<div style={{ position: "fixed", zIndex: 5 }}>
          <GoBackFabBtn />
        </div>*/}

        <Header isHome={false} />
        <div className="bannerEvent">
          <img className="bannerEventImg" src={bannerevent} alt="banner" />
        </div>
        {/*<Slides featured={featured} />*/}
        <div className="containerEvents">
          <div className="filterEvents">
            <button
              variant="contained"
              className="filterButtonOpen"
              onClick={toggleDrawer(true)}
            >
              <img
                src={FilterIcon}
                alt="icon"
                style={{
                  color: "white",
                  backgroundColor: "white",
                  width: "20px",
                  textAlign: "center",
                  padding: "5px",
                }}
              />
              FILTRAR
            </button>

            {/* Drawer (Menú Lateral) */}
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <div role="presentation" className="divFilter">
                <h3>Filtros</h3>
                <List>
                  <Divider className="dividerStyle" />
                  <ListItem>
                    <FormControl fullWidth>
                      {/*<InputLabel id="brand-select-label">MARCAS</InputLabel>*/}
                      <Select
                        options={brands.map((brand) => ({
                          value: brand.value,
                          label: brand.label,
                        }))}
                        placeholder="MARCAS"
                        onChange={(selected) =>
                          setSelectedBrand(selected || [])
                        } // Maneja selección múltiple
                        value={selectedBrand}
                        isMulti={true} // Activa la selección múltiple
                        isSearchable={false}
                        //menuPortalTarget={document.body}
                        styles={testStyle2}
                      />
                    </FormControl>
                  </ListItem>
                  <Divider className="dividerStyle" />
                  <ListItem>
                    <FormControl fullWidth>
                      <Select
                        options={stores.map((store) => ({
                          value: store.value,
                          label: store.label,
                        }))}
                        placeholder="SUCURSALES"
                        value={selectedStore}
                        onChange={(selected) =>
                          setSelectedStore(selected || [])
                        } // Maneja selección múltiple
                        isMulti={true} // Selección simple
                        isSearchable={false}
                        //menuPortalTarget={document.body} // Evita solapamiento flotante
                        styles={testStyle2}
                      />
                    </FormControl>
                  </ListItem>
                  <Divider className="dividerStyle" />
                  <ListItem>
                    <FormControl fullWidth>
                      {/*<InputLabel id="location-select-label">
                        LOCALIDADES
                      </InputLabel>*/}
                      <Select
                        options={locations.map((location) => ({
                          value: location.value,
                          label: location.label,
                        }))}
                        placeholder="LOCALIDADES"
                        value={selectedLocation}
                        onChange={handleLocationChange}
                        isMulti={false} // Selección simple
                        isSearchable={false}
                        //menuPortalTarget={document.body} // Evita solapamiento flotante
                        styles={testStyle2}
                      />
                    </FormControl>
                  </ListItem>
                  <Divider className="dividerStyle" />
                  <ListItem>
                    <FormControl fullWidth>
                      {/*<InputLabel id="category-select-label">
                        CATEGORIAS
                      </InputLabel>*/}
                      <Select
                        options={categories.map((category) => ({
                          value: category.value,
                          label: category.label,
                        }))}
                        placeholder="CATEGORIAS"
                        value={selectedCategory}
                        onChange={(selected) =>
                          setSelectedCategory(selected || [])
                        }
                        isMulti={true} // Activar la selección múltiple
                        // menuPortalTarget={document.body}
                        isSearchable={false}
                        styles={testStyle2}
                      />
                    </FormControl>
                  </ListItem>
                  <Divider className="dividerStyle" />
                </List>
                <div className="buttonContainer">
                  <button
                    variant="contained"
                    className="filterButtonClean"
                    onClick={handleClearFilters}
                    disabled={
                      selectedCategory.length === 0 &&
                      selectedBrand.length === 0 &&
                      selectedStore.length === 0 &&
                      selectedLocation === "" // Deshabilitar si no hay filtros aplicados
                    }
                  >
                    LIMPIAR
                  </button>
                  <button
                    variant="contained"
                    className="filterButtonApply"
                    onClick={filterApply}
                    disabled={
                      selectedCategory.length === 0 &&
                      selectedBrand.length === 0 &&
                      selectedStore.length === 0 &&
                      selectedLocation === "" // Deshabilitar si no hay filtros aplicados
                    }
                  >
                    APLICAR
                  </button>
                </div>
              </div>
            </Drawer>

            {/*  <Select
              options={categories} // Las opciones que puedes seleccionar
              styles={isMobile ? customStylesMobile : customStyles} // Estilos condicionales
              defaultValue="" // Valor por defecto
              placeholder="CATEGORIAS" // Texto de marcador de posición
              isSearchable={false} // Deshabilita la búsqueda si no es necesario
              isMulti // Activa el modo de selección múltiple
              onChange={handleCategoryChange} // Manejador de cambio
            />
            <Select
              options={brands}
              styles={isMobile ? customStylesMobile : customStyles}
              //defaultValue={brands[0]}
              defaultValue=""
              placeholder="MARCAS"
              isSearchable={false}
              onChange={handleBrandChange}
            />
            <Select
              options={locations}
              styles={isMobile ? customStylesMobile : customStyles}
              //defaultValue={locations[0]}
              defaultValue=""
              placeholder="LOCALIDADES"
              isSearchable={false}
              onChange={handleLocationChange}
            />*/}

            {/* <div class="custom-select">
              <select
                className="selectEvents"
                value={selectedCategory}
                onChange={handleCategoryChange}
                defaultValue=""
              >
                <option value="">CATEGORIA</option>
                {categories.map((category) => {
                  return (
                    <option value={category.value}>{category.desc}</option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                className="selectEvents"
                value={selectedBrand}
                onChange={handleBrandChange}
                defaultValue=""
              >
                <option value="">MARCAS</option>
                {brands.map((bran) => {
                  return (
                    <option value={bran.id}>{bran.name.toUpperCase()}</option>
                  );
                })}
              </select>
            </div>
            <div>
              <select
                className="selectEvents"
                value={selectedLocation}
                onChange={handleLocationChange}
                defaultValue=""
              >
                <option value="">LOCALIDAD</option>
                {locations.map((location) => {
                  return <option value={location}>{location}</option>;
                })}
              </select>
            </div>*/}
          </div>
          {!isMobile && (
            <div className="containerTitleFilter">
              <hr className="hrFilter" />
              {filterText && <span>{filterText}</span>}
            </div>
          )}

          {filteredList.length == 0 ? (
            isMobile ? (
              <img className="img-noEvents" src={noEventsM} />
            ) : (
              <img className="img-noEvents" src={noEvents} />
            )
          ) : (
            <div className="conteinerMain">
              <div className="containerCard">
                {filteredList?.map((eventFilter) => (
                  <EventCards event={eventFilter} />
                ))}
              </div>
            </div>
          )}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export { Events };
