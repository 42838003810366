import { Link } from "react-router-dom";
import {
  FiCard,
  FiCardActionArea,
  FiCardContent,
  FiCardMedia,
} from "./FullImageCard";
import makeStyles from "@material-ui/core/styles/makeStyles";
import './styles.css'
import consultImageM from "../../assets/BannerMobile/mimgCita-min.jpg";
import consultImageD from "../../assets/BannerDesktop/imgcita-min.jpg";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  card: {
    width: '100%',
    boxShadow: '0px 2px 1px -1px rgb(255, 255, 255),0px 1px 1px 0px rgb(255, 255, 255),0px 1px 3px 0px rgb(255, 255, 255)',
  },
  media: {
    height: 140,
    '@media (max-width: 600px)': {
      height: "40vw" // Reduce el tamaño de la fuente en pantallas pequeñas
    },
  },

  fiCardContent: {
    color: "#ffffff",
    backgroundColor: "rgba(0,0,0,0)",

    height: "40vh",
    textAlign: "center",
    '@media (max-width: 550px)': {
      padding: "0px",
      height: "40vw", // Reduce el tamaño de la fuente en pantallas pequeñas
      paddingTop: '1vw',
      paddingBottom: '1vw',
    },

  },
  fiCardContentTextSecondary: {
    color: "rgba(255,255,255,0.78)",
  },
  containerTitle: {
    position: "relative",
    top: "40%",
    fontSize: 25,
    fontWeight: 600,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  img: {
    backgroundSize: "contain",

  },
});

const CardButtonNav = ({ title, srcImage, linkNav, onClick }) => {
  const classes = useStyles();
  return (
    <FiCard className={classes.card} onClick={onClick}>
      <Link className={classes.link} to={linkNav}>
        <FiCardActionArea>
          <FiCardMedia
            className={classes.img}
            media="picture"
            alt="Contemplative Reptile"
            image={srcImage}
            //image="https://www.online-911.com/wp-content/uploads/2016/10/juleriaqueunicenter.jpg"
            title="Contemplative Reptile"
          />
          <FiCardContent className={`fiCardContentR ${classes.fiCardContent}`}>
            <div className={`containerTitle ${classes.containerTitle}`}>{title}</div>
          </FiCardContent>
        </FiCardActionArea>
      </Link>
    </FiCard>
  );
};

export { CardButtonNav };
