import React, { useEffect, useState } from "react";
import { useConsultant } from "../../hooks";
/*import {
  setStackHoursStore,
  // currentWeek,
} from "../../utils"; */
import { CircularProgress, Button } from "@material-ui/core";
import { ZONES } from "../../utils";
import "./styles.css";
import { CalendarConsult } from "../../components/CalendarConsult";
import { EmptyCalendar } from "../../components/EmptyCalendar";
import { GoBackFabBtn } from "../../components/GoBackButton";
import ConsBannerM from "../../assets/BannerMobile/mheaderCita-min.jpg";
import ConsBannerD from "../../assets/BannerDesktop/headerCons-min.jpg";
import { Footer } from "../../components/Footer";

const ConsultantBooking = () => {
  const {
    stores,
    zones,
    // consultants,
    getWorkDays,
    workingDays,
    restingDays,
    //getRestDays,
    //bookingConsultant,
    isFetching,
    dataCalendar,
    sendDataBooking,
    getDaysEvent,
    daysEventStore,
  } = useConsultant();

  const [selectedZone, setSelectedZone] = useState(null);
  const [newBookingConsultant, setNewBookingConsultant] = useState({});
  const [brandsStore, setBrandsStore] = useState(null);
  const [consultantsBrand, setConsultantsBrand] = useState(null);
  const [store, setStore] = useState("");
  const [brand, setBrand] = useState(null);
  const [selectConsultant, setSelectConsultant] = useState(null);
  const [selectedDayWork, setSelectedDayWork] = useState(null);
  const [timeEventHour, setTimeEventHour] = useState(null);
  const [emailValidate, setEmailValidate] = useState("inicial");
  const [phoneValidate, setPhoneValidate] = useState("inicial");
  const [dateBooking, setDateBooking] = useState(null);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;

  useEffect(() => {
    if (stores) setSelectedZone(zones[0]);
  }, [stores]);

  useEffect(() => {
    console.log("restinggggggg: ", restingDays);
    console.log("workinggggggg: ", workingDays);
  }, []);
  const handleChange = (e) => {
    setNewBookingConsultant({
      ...newBookingConsultant,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectBrand = (event) => {
    // setStackConsultants([])
    setBrand(event.target.value);
    setSelectConsultant(null);
    setConsultantsBrand(brandsStore[event.target.value]);
    setSelectedDayWork(null);
  };

  const handleSelectSltore = (event) => {
    // setStackConsultants([]);
    setStore(event.target.value);
    // Ordenamiento de brands en orden alfabetico
    const unorderBrands = stores[selectedZone][event.target.value];
    const orderBrands = {};
    Object.keys(unorderBrands)
      .sort()
      .forEach(function (key) {
        orderBrands[key] = unorderBrands[key];
      });
    setBrandsStore(orderBrands);
  };

  const handleZone = (zone) => {
    // setStackConsultants([]);
    setSelectedZone(zone);
    setSelectConsultant(null);
    setBrandsStore(null);
    setConsultantsBrand(null);
    setSelectedDayWork(null);
  };
  console.log("restinggggggg: ", restingDays);
  console.log("workinggggggg: ", workingDays);
  /*const isMobile = window.matchMedia(
    "only screen and (max-width: 760px)"
  ).matches;*/

  const handleConsultant = (ev, consultant) => {
    setSelectedDayWork(null);
    getDaysEvent(consultant.storeId);
    setSelectConsultant(consultant);
    getWorkDays(consultant.id);
    //getRestDays(consultant.id);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();

    const foundBrandID = consultantsBrand.map((consult) => {
      return consult.brands.find((br) => {
        return brand === br.name;
      });
    });
    // console.log("foundBrandID-----------", foundBrandID);
    sendDataBooking({
      ...newBookingConsultant,
      selectTime: timeEventHour,
      selectDay: dateBooking,
      storeId: selectConsultant.storeId,
      brandId: foundBrandID[0].id,
      consultantId: selectConsultant.id,
    });
  };

  const requeridoEmail = (e) => {
    let expresion = /\w+@\w+.[a-z]/;
    //     \w (es texto)
    //     . (es punto)
    if (
      newBookingConsultant.email !== "" &&
      expresion.test(newBookingConsultant.email)
    ) {
      setEmailValidate("mostrar");
    } else {
      // el input esta vacio
      setEmailValidate("nomostrar");
    }
  };

  const requeridoPhone = (e) => {
    let expresion = /^(0|[1-9]\d*)$/;
    //     \w (es texto)
    //     . (es punto)
    if (
      newBookingConsultant.tel !== "" &&
      expresion.test(newBookingConsultant.tel)
    ) {
      setPhoneValidate("mostrar");
    } else {
      // el input esta vacio
      setPhoneValidate("nomostrar");
    }
  };

  const formatBrand = (str) => {
    return str
      .toLowerCase()
      .replace(/^[a-z]/g, (x) => x.toUpperCase())
      .replace(/-([a-z]|\&)/g, (_, x) => " " + x.toUpperCase());
  };

  const bannerconsult = isMobile ? ConsBannerM : ConsBannerD;

  return (
    <div>
      <div className="bannerEvent">
        <img className="bannerEventImg" src={bannerconsult} alt="banner" />
      </div>
      <div className="react-container">
        <div className="containerTittleConsul">
          <div className="tittleConsul">
            AGENDÁ TU CITA CON TU CONSULTORA DE CONFIANZA
          </div>
        </div>

        <div className="calendar-container">
          {stores && selectedZone && (
            <>
              <div className="calendar">
                {ZONES.map((zone) => {
                  //console.log("zoneeeeeeeeee", zone);
                  if (zone !== "VIRTUAL" && zone !== "ESPECIAL") {
                    let active = selectedZone.toUpperCase() === zone;
                    let include = zones.includes(zone);
                    let zone2 = zone;

                    if (zone2 === "GRAN BUENOS AIRES") zone2 = "GBA";
                    if (zone2 === "MAR DEL PLATA") zone2 = "MDQ";

                    return (
                      <div
                        key={zone}
                        onClick={() => (include ? handleZone(zone) : null)}
                        className={`zone ${active ? "active" : ""}`}
                        style={{
                          borderBottom: active ? "1 rem solid" : "none",
                          display: !include && "none",
                          fontWeight: active ? 800 : 500,
                        }}
                      >
                        {zone2}
                      </div>
                    );
                  }
                })}
              </div>
              <div className="form-calendarr">
                <form onSubmit={handleSubmit} className="order input-container">
                  <div className="div_form">
                    <div className="grid-input">
                      <label className="label">SUCURSAL:</label>
                      <select
                        name="store"
                        className="input"
                        onChange={handleSelectSltore}
                        value={store}
                      >
                        <option value="seleccione">Seleccione</option>;
                        {Object.keys(stores[selectedZone])
                          .sort((a, b) => a.localeCompare(b)) // Ordena alfabéticamente las claves
                          .map((store, i) => (
                            <option key={i} value={store}>
                              {store}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="grid-input">
                      <label className="label">MARCA:</label>
                      <select className="input" onChange={handleSelectBrand}>
                        <option value="seleccione">Seleccione</option>;
                        {brandsStore &&
                          Object.keys(brandsStore).map((brand, i) => {
                            let numbers = ["1", "2"];
                            if (!numbers.includes(brand[0])) {
                              return (
                                <option key={i} value={brand}>
                                  {formatBrand(brand)}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div>
                    {consultantsBrand && (
                      <>
                        {consultantsBrand.length === 0 && (
                          <div className="container-text-consultant">
                            Sin consultores disponibles para la marca
                            seleccionada.
                          </div>
                        )}
                        <div className="container-wrap-consultants">
                          {/*consultantsBrand.filter((c => c.status === "active")).length === 0 &&
                          (
                            <div className="container-text-consultant">
                              Sin consultores para marca seleccionada
                            </div>
                          )
                          */}
                          {consultantsBrand
                            .filter((c) => c.status === "active")
                            .map((consultant) => {
                              let select =
                                selectConsultant?.id === consultant.id;
                              return (
                                <div
                                  key={consultant.id}
                                  style={{
                                    backgroundColor: select
                                      ? "rgb(165 165 165)"
                                      : "white",
                                    color: select && "white",
                                  }}
                                  className="consultant"
                                  onClick={(ev) =>
                                    handleConsultant(ev, consultant)
                                  }
                                >
                                  {consultant.name.charAt(0).toUpperCase() +
                                    consultant.name.slice(1)}
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}

                    <div className="order grid-calendario">
                      <div className="divCalendar">
                        <label className="label">¿CUÁNDO DESEAS ASISTIR?</label>
                        <div>
                          {selectConsultant && (
                            <CalendarConsult
                              brandsStore={brandsStore}
                              selectedZone={selectedZone}
                              setSelectDay={setSelectedDayWork}
                              selectedDay={selectedDayWork}
                              timeConsultant={workingDays}
                              setDateBooking={setDateBooking}
                              selectConsultant={selectConsultant}
                              dataCalendar={dataCalendar}
                              workingDays={workingDays}
                              daysEventStore={daysEventStore}
                            />
                          )}
                          {!selectConsultant && <EmptyCalendar />}
                        </div>
                      </div>
                    </div>

                    {workingDays && selectedDayWork && (
                      <div className="order grid-time">
                        <label className="label">ELIGE UN HORARIO:</label>
                        <div className="grid-item-time">
                          {console.log(
                            "dataaaaaaa",
                            dataCalendar,
                            selectedDayWork
                          )}
                          {Object.keys(
                            dataCalendar[selectConsultant.id][selectedDayWork]
                          ).map((time) => {
                            let active = timeEventHour === time;
                            let inactive =
                              dataCalendar[selectConsultant.id][
                                selectedDayWork
                              ][time].cupos <= 0;
                            return (
                              <div
                                key={time}
                                onClick={() =>
                                  !inactive ? setTimeEventHour(time) : null
                                }
                                className="item-time"
                                style={{
                                  backgroundColor: active
                                    ? "#a5a5a5"
                                    : inactive
                                    ? "gray"
                                    : "white",

                                  color: active ? "white" : "#4a4a4a",

                                  cursor: inactive ? "not-allowed" : "pointer",
                                }}
                              >
                                {time}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}

                    <div className="grid-input">
                      <label className="label">NOMBRE:</label>
                      <input
                        name="nombre"
                        className="input"
                        type="text"
                        onChange={handleChange}
                        // onChange={(ev) => ({
                        //   ...dataForm,
                        //   nombre: ev.target.value,
                        // })}
                      />
                    </div>
                    <div className="grid-input">
                      <label className="label">APELLIDO:</label>
                      <input
                        name="apellido"
                        className="input"
                        type="text"
                        onChange={handleChange}
                        // onChange={(ev) => ({
                        //   ...dataForm,
                        //   apellido: ev.target.value,
                        // })}
                      />
                    </div>
                    <div className="grid-input">
                      <label className="label">E-MAIL:</label>
                      <input
                        name="email"
                        className="input"
                        // className={emailValidate == "inicial" ? "" : emailValidate == "mostrar"  ? "is-valid" : "is-invalid" }
                        type="email"
                        onChange={handleChange}
                        onBlur={requeridoEmail}
                        // onChange={(ev) => (dataForm.email = ev.target.value)}
                      />
                      {emailValidate === "nomostrar" && (
                        <div className="invalid-feedback">
                          Ingrese un email válido
                        </div>
                      )}
                    </div>
                    <div className="grid-input">
                      <label className="label">TELÉFONO:</label>
                      <input
                        name="tel"
                        className="input"
                        type="text"
                        onChange={handleChange}
                        onBlur={requeridoPhone}
                        // onChange={(ev) => (dataForm.tel = ev.target.value)}
                      />
                      {phoneValidate === "nomostrar" && (
                        <div className="invalid-feedback">
                          Ingrese un número de teléfono válido
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="containerButtonConsult">
                    <Button
                      className="buttonFormAgenda"
                      type="submit"
                      variant="contained"
                      // onClick={onClick}
                      disabled={
                        isFetching ||
                        !newBookingConsultant.email ||
                        !newBookingConsultant.nombre ||
                        !timeEventHour
                      }
                    >
                      {isFetching && <CircularProgress size={14} />}
                      {!isFetching && "AGENDAR"}
                    </Button>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export { ConsultantBooking };
