import React from "react";
import { Box, Grid, IconButton, Typography, Divider } from "@material-ui/core";
import Youtube from "../../assets/Footer/icono-youtube.jpg";
import Facebook from "../../assets/Footer/icono-facebook.jpg";
import Instagram from "../../assets/Footer/icono-instagram.jpg";
import Tiktok from "../../assets/Footer/icono-tiktok.jpg";
import Linkedin from "../../assets/Footer/icono-linkedin.jpg";
import Mail from "../../assets/Footer/icono-mail.jpg";
import Beautyblog from "../../assets/Footer/icono-beautyblog.jpg";
import Flecha from "../../assets/Footer/flecha-arriba.png";

import { Link } from "react-router-dom";
import "./styles.css";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const Footer = () => {
  return (
    <Box className="footerBox">
      {/* Sección de íconos con las líneas */}
      <IconButton
        className="iconButtonFlecha"
        color="primary"
        onClick={scrollToTop}
      >
        <img className="iconImageFlecha" src={Flecha} alt="Flecha" />
      </IconButton>
      <Grid container justifyContent="center" alignItems="center">
        {/* Línea desde la izquierda hasta el primer ícono */}
        <Grid item className="hrSpce">
          <hr className="hrFooter" />
        </Grid>

        {/* Íconos circulares */}
        <Grid item className="bodySpce">
          <Box className="boxIcon" display="flex" alignItems="center">
            <a
              href="https://www.facebook.com/Juleriaque"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Facebook} alt="Facebook" />
              </IconButton>
            </a>

            <a
              href="https://www.instagram.com/perfumeriasjuleriaque/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Instagram} alt="Instagram" />
              </IconButton>
            </a>

            <a
              href="https://www.tiktok.com/@perfumeriasjuleriaque"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Tiktok} alt="Tiktok" />
              </IconButton>
            </a>

            <a
              href="https://ar.linkedin.com/company/juleriaque-s.a"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Linkedin} alt="Linkedin" />
              </IconButton>
            </a>

            <a
              href="https://www.youtube.com/c/PerfumeriasJuleriaqueOK"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Youtube} alt="Youtube" />
              </IconButton>
            </a>

            <a
              href="mailto:consultas@juleriaque.com.ar"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Mail} alt="Mail" />
              </IconButton>
            </a>

            <a
              href="https://www.thebeautyblog.com.ar/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                className="iconButton"
                color="primary"
                sx={{ padding: 0 }}
              >
                <img className="iconImage" src={Beautyblog} alt="Beautyblog" />
              </IconButton>
            </a>
          </Box>
        </Grid>

        {/* Línea desde el último ícono hasta el borde derecho */}
        <Grid item className="hrSpce">
          <hr className="hrFooter" />
        </Grid>
      </Grid>

      {/* Texto de derechos reservados */}
      <Typography className="footerText">
        © 2022 JULERIAQUE. TODOS LOS DERECHO RESERVADOS.
      </Typography>
    </Box>
  );
};

export { Footer };
