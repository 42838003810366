import React from 'react'
import { useHistory } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  fabGreen: {
    top: 80,
    left: 15,
    color: theme.palette.common.black,
    backgroundColor: "white",
    "&:hover": {
      // backgroundColor: indigo[100],
      color: theme.palette.common.white,
    },
  },
}));

const GoBackFabBtn = () => {
  const history = useHistory();
  const classes = useStyles();
  //const theme = useTheme();

  return (
    <Fab onClick={() => {
      history.goBack();
    }} size="medium" aria-label="Expand" className={classes.fabGreen} color="inherit">
      <ArrowBackIcon />
    </Fab>
  )
}

export { GoBackFabBtn }